//Stores all the action types

export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const GET_ANIMAL = 'GET_ANIMAL';
export const ADD_COMMENT = 'ADD_COMMENT';
export const GET_COMMENTS = 'GET_COMMENTS';
export const ADD_REPLY = 'ADD_REPLY';
export const ADD_SUBREPLY = 'ADD_SUBREPLY';
export const LIKE = 'LIKE';
export const EDIT_COMMENT = 'EDIT_COMMENT';
export const EDIT_REPLY = 'EDIT_REPLY';
export const EDIT_SUBREPLY = 'EDIT_SUBREPLY';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const DELETE_REPLY = 'DELETE_REPLY';
export const DELETE_SUBREPLY = 'DELETE_SUBREPLY';
export const ADD_EVENT = 'ADD_EVENT';
export const GET_EVENTS = 'GET_EVENTS';
export const EDIT_EVENT = 'EDIT_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const UPDATE_ATTENDANCE = 'UPDATE_ATTENDANCE';